import React, { useState } from "react";
// Components
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import ModalOrBottomSheetWrapper, {
    ModalOrBottomSheetWrapperProps
} from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import { ModalCrossIcon } from "@vahak/core-ui/dist/components/Modal/Modal";
// Hooks
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types";
import UserCurrentLocation from "../../../../posting-form/common/current-location/UserCurrentLocation";
import { LocationSearchSource } from "@vahak/core/dist/_services/city-search.service";

interface LorryReactivateModalProps
    extends Pick<ModalOrBottomSheetWrapperProps, "titleText" | "id" | "onToggle" | "isOpen"> {
    onAction?: (place: PlaceSearchResult) => void;
}

const LorryReactivateModal = ({
    titleText = "Enter current vehicle location to re-activate Lorry",
    id = "reactivate",
    onToggle,
    isOpen,
    onAction
}: LorryReactivateModalProps) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const [location, setLocation] = useState<PlaceSearchResult>();
    return (
        <ModalOrBottomSheetWrapper
            onToggle={onToggle}
            isOpen={isOpen}
            renderTitle={
                <div data-popup-header>
                    <Typography weight="bold" size="l" mSize="m">
                        {titleText}
                    </Typography>
                    <ModalCrossIcon id={id} onClick={onToggle} />
                </div>
            }
        >
            <Flex gap={32} flexDirection="column" style={{ marginBottom: 20 }}>
                <UserCurrentLocation
                    placeholder="Search lorry's current location"
                    showUserCurrentLocation
                    id={id + "_txtf_src"}
                    onChange={setLocation}
                    value={location}
                    autoFocus
                    dropdownProps={{
                        maxMenuHeight: 80,
                        menuPosition: "absolute"
                    }}
                    locationSearchSource={LocationSearchSource.LorryPosting}
                />

                <div
                    {...(!isMobileScreen && {
                        style: {
                            alignSelf: "flex-end"
                        }
                    })}
                >
                    <Button
                        blockBtn={isMobileScreen}
                        onClick={async () => !!location && (await onAction?.(location))}
                        disabled={!location}
                        id={id + "_btn_submit"}
                    >
                        Re-activate
                    </Button>
                </div>
            </Flex>
        </ModalOrBottomSheetWrapper>
    );
};

export default LorryReactivateModal;
