import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import API from "@vahak/core/dist/_services/const";

interface AttachLorries {
    attachlorries: { bid_id: number; lorry_id: number }[];
}

interface AttachLorriesResponse {
    data: {
        results: { id: number; status: number; message: string }[];
    };
}

const useAttachLorriesAndBids = () => {
    const { POST } = useVahakHttpClient();

    function attachLorry(data: AttachLorries) {
        return POST<AttachLorries, AttachLorriesResponse>(API.ATTACH_LORRY_TO_BID, { ...data });
    }

    return useVahakMutationQuery<AttachLorriesResponse, unknown, AttachLorries>("trialtt", attachLorry);
};
export default useAttachLorriesAndBids;
