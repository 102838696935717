import API from "@vahak/core/dist/_services/const";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import {
    ClientHookInfiniteQueryOptions,
    ClientHookOptions,
    useVahakInfiniteQuery,
    useVahakQuery
} from "@vahak/core/dist/hooks/useVahakQueryClient";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import { removeEmptyOrNullObjectValue } from "@vahak/core/dist/methods/remove-empty-or-null-object-value";
import { LorryListResponse, LorryListResponseData } from "./type";
import { getArrayWithType } from "@vahak/core/dist/methods";
import { LORRY_STATUS } from "@vahak/core/dist/constants";

type Preload =
    | "company_detail"
    | "bid_infos"
    | "bid_infos.bidder_info"
    | "bid_infos.tenderer_info"
    | "bid_infos.load_detail"
    | "bid_infos.lorry_detail"
    | "bid_infos.last_call_details"
    | "bid_infos.spot_load_info"
    | "bid_infos.tap_n_go_info"
    | "bid_infos.load_detail.load_address"
    | "bid_infos.payment_details"
    | "minimum_bid_info"
    | "trip_current_location_details"
    | "rcdata"
    | "ulip_info"
    | "gps_location_details"
    | "lorry_city_routes";

interface Params {
    companyId: number;
    request_type?: number;
    type?: string[];
    status?: (LORRY_STATUS | string)[];
    created_at_string?: string;
    id?: number[];
    preloads?: Preload[];
    limit?: number;
    filter_out_phantom_lorry?: boolean;
    city_radius_km?: number;
    capacity?: {
        min: number;
        max: number;
    };
    size?: {
        min: number;
        max: number;
    };
    lorry_tyre?: {
        min: number;
        max: number;
    };
}

export const useGetAllLorriesV2 = (
    params: Params,
    options: ClientHookOptions<LorryListResponse, unknown, LorryListResponse["data"]>
) => {
    const { POST } = useVahakHttpClient();
    const {
        companyId,
        request_type,
        status,
        created_at_string,
        type,
        id,
        preloads,
        limit,
        filter_out_phantom_lorry,
        capacity,
        city_radius_km,
        lorry_tyre,
        size
    } = params;

    const method = async ({ pageParam = 1 }) => {
        const payload = removeEmptyOrNullObjectValue({
            company_id: companyId,
            page_number: pageParam,
            request_type,
            status: getArrayWithType(status, String) as string[],
            created_at_string,
            type,
            id,
            preload: preloads,
            limit,
            filter_out_phantom_lorry,
            capacity,
            city_radius_km,
            lorry_tyre,
            size
        });
        return POST<unknown, LorryListResponse>(API.ALL_LORRIES_V2, payload);
    };
    return useVahakQuery<LorryListResponse, unknown, LorryListResponse["data"]>(
        [QueryNames.getLorryAllDataV2, params],
        method,
        {
            ...options,
            select: (data) => data.data ?? []
        }
    );
};

export const useGetAllLorriesInfiniteListV2 = (
    params: Params,
    options: ClientHookInfiniteQueryOptions<LorryListResponse, unknown, LorryListResponseData>
) => {
    const { POST } = useVahakHttpClient();
    const { companyId, request_type, status, created_at_string, type, id, preloads, limit, filter_out_phantom_lorry } =
        params;

    const method = async ({ pageParam = 1 }) => {
        const payload = {
            company_id: companyId,
            page_number: pageParam,
            request_type,
            status: getArrayWithType(status, String) as string[],
            created_at_string,
            type,
            id,
            preload: preloads,
            limit,
            filter_out_phantom_lorry
        };
        return POST<unknown, LorryListResponse>(API.ALL_LORRIES_V2, payload);
    };

    return useVahakInfiniteQuery<LorryListResponse, unknown>([QueryNames.getLorryAllDataV2, params], method, {
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data?.page_number < lastPage?.data?.total_pages) return lastPage.data?.page_number + 1;
            return undefined;
        },
        ...options,
        onSuccess: (data) => {
            options.onSuccess([
                {
                    ...data?.pages?.[0]?.data,
                    lorry_cards: data?.pages?.flatMap((page) => page?.data?.lorry_cards ?? []) ?? []
                }
            ]);
        }
    });
};
